import "core-js/modules/web.dom.iterable";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Pagination from "@/components/Pagination";
import { fetchCreditRecordList } from "../../../api/finance";
import { fetchMemberList } from "@/api/member";
import "@/utils/global.js";
export default {
  name: "AgentFinanceWithdraw",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        filter: {
          user_id: '',
          from: "",
          start_time: "",
          end_time: "",
          keyword: ""
        },
        time: []
      },
      pickerOptions: _global.timeOption,
      bill_date: [],
      // 表格数据
      list: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      //搜索用户
      memberLoading: false,
      memberOptions: [],
      fromList: [{
        value: 0,
        label: "后台充值"
      }, {
        value: 1,
        label: "充电消费"
      }, {
        value: 2,
        label: "充电消费退回"
      }, {
        value: 3,
        label: "充电分销佣金"
      }, {
        value: 4,
        label: "充电桩拥有者佣金"
      }, {
        value: 5,
        label: "购买充电桩"
      }, {
        value: 6,
        label: "区域代理"
      }, {
        value: 7,
        label: "积分提现"
      }, {
        value: 8,
        label: "推荐商家"
      }, {
        value: 9,
        label: "余额扣款"
      }, {
        value: 10,
        label: "余额充值"
      }]
    };
  },
  created: function created() {
    this.fetchList();
  },
  methods: {
    fetchList: function fetchList() {
      var _this = this;

      this.tableLoading = true;
      fetchCreditRecordList(this.searchForm).then(function (response) {
        _this.list = response.data;
        _this.totalPage = response.meta.total;
        _this.tableLoading = false;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      console.log("搜索");
      this.searchForm.page = 1;
      this.fetchList();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      this.searchForm.page = val;
      this.fetchList();
    },
    handlePageSize: function handlePageSize(val) {
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
      this.fetchList();
    },
    resetForm: function resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //搜索用户
    remoteMember: function remoteMember(query) {
      var _this2 = this;

      if (query !== "") {
        this.memberLoading = true;
        setTimeout(function () {
          _this2.memberLoading = false;
          var data = {
            keyword: query
          };
          fetchMemberList(data).then(function (res) {
            console.log(res);
            _this2.memberOptions = res.data;
          });
        }, 100);
      } else {
        this.memberOptions = [];
      }
    },
    handleTime: function handleTime(val) {
      if (val) {
        this.searchForm.filter["start_time"] = val[0];
        this.searchForm.filter["end_time"] = val[1];
      } else {
        this.searchForm.filter["start_time"] = "";
        this.searchForm.filter["end_time"] = "";
      }
    },
    getFromValue: function getFromValue(value) {
      var label = '-';
      this.fromList.forEach(function (item) {
        if (item.value == value) {
          label = item.label;
        }
      });
      return label;
    }
  }
};